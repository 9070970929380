<template>
  <Layout>
    <div class="containerx">
      <div class="flex flex-row gap-1 my-2 pl-4">
        <ButtonPlay @click="handleChangeView('tema/app/listaTema')">Banco de Temas</ButtonPlay>
        <ButtonPlay @click="handleChangeView('redacao/app/lista')">Minhas Redações</ButtonPlay>
      </div>
      <iframe v-if="iframeUrl && !isLoading"
        :src="`${iframeUrl}?tid=${tid}&pid=${pid}&usuarioParceiroId=${usuarioParceiroId}&token=${token}`" width="100%"
        style="
          height: 100vh;
          border: none;
          overflow: hidden;
          margin: 0;
          padding: 0;
        "></iframe>
      <div v-else class="d-flex justify-content-center align-items-center" style="height: 100vh;">
        <b-spinner label="Loading..."></b-spinner>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/layout/Layout.vue"
import EssayService from '@/services/resources/EssayService.js'
import ButtonPlay from '@/components/common/ButtonPlay.vue'

const essay = EssayService.build();

export default {
  components: {
    Layout,
    ButtonPlay
  },
  data() {
    return {
      tid: 0,
      pid: 0,
      token: 0,
      usuarioParceiroId: 0,
      iframeUrl: false,
      isLoading: false,
    }
  },
  methods: {
    async getEssay() {
      this.isLoading = true;
      const data = await essay.search();
      const { tid, pid, token, iframeUrl, usuarioParceiroId } = data;
      this.tid = tid;
      this.pid = pid;
      this.token = token;
      this.usuarioParceiroId = usuarioParceiroId;
      this.iframeUrl = iframeUrl;
      this.isLoading = false;
    },
    handleChangeView(url) {
      const host = this.iframeUrl.split('/').slice(0, 3).join('/');
      this.iframeUrl = `${host}/${url}`;
    }
  },
  mounted() {
    this.getEssay();
  },

}
</script>

<style scoped>
.containerx {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow-y: hidden;
}
</style>